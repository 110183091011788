import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { InView } from "react-intersection-observer";
import Input from "./elements/input";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { scrollOnFormStep, submitForm, typLeadStep } from "../utility/config-form-contatti";
import useNomePacchetto from "../utility/use-nome-pacchetto";
import { validatePhoneIT } from "../utility/validate-form";
import Icon from "./elements/icon-svg";

const FormChat = ({ location, response, setResponse, setOpenForm, typeForm }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm({ mode: "onTouched" });

  const responseRef = useRef();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const submit = async (values) => {
    if (!executeRecaptcha) {
      console.warn("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha("formCasa");

    try {
      const result = await submitForm(
        { ...values, grecaptcha: token },
        {
          provUrlType: { value: "complete" },
          prodottoInteressato: { value: typeForm },
          formAdvname: { value: "" },
        },
        location
      );
      console.log("Submit result: ", result);
      typLeadStep(typeForm, location);
      setResponse("ok");
    } catch (err) {
      console.error("Error submit: ", err);
      setResponse("ko");
    }
    if (responseRef.current) {
      responseRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  };

  const nomePacchetto = useNomePacchetto();

  return (
    <>
      <InView
        as="section"
        threshold={0.1}
        triggerOnce={true}
        onChange={(inView) => inView && scrollOnFormStep(typeForm, location, nomePacchetto)}
        className="widget-chat__form"
      >
        <div className="row h-100">
          <div className="col-md-12">
            {!response && (
              <form className="h-100 d-flex flex-column" onSubmit={handleSubmit(submit)}>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="widget-chat__form-title">
                      {typeForm === "INATT"
                        ? "Inserisci qui i tuoi dati e ti assisteremo nell'attivazione della fornitura."
                        : "Inserisci qui i tuoi dati e ti richiameremo per darti le informazioni di cui hai bisogno."}
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-6 mb-4 mb-md-0">
                    <Input
                      name="name"
                      register={register}
                      validation={{
                        required: {
                          value: true,
                          message: "Per favore compila questo campo per farti contattare.",
                        },
                        pattern: {
                          value: /^[a-z\u00C0-\u017F'\s]+$/i,
                          message: "Inserire solo lettere e spazi e non più di 40 caratteri",
                        },
                        maxLength: {
                          value: 40,
                          message: "Inserire solo lettere e spazi e non più di 40 caratteri",
                        },
                      }}
                      placeholder="Nome"
                      movingLabel={false}
                      iconName="user"
                      error={errors.name}
                      touched={touchedFields.name}
                      check={true}
                      checkError={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      name="surname"
                      register={register}
                      validation={{
                        required: {
                          value: true,
                          message: "Per favore compila questo campo per farti contattare.",
                        },
                        pattern: {
                          value: /^[a-z\u00C0-\u017F'\s]+$/i,
                          message: "Inserire solo lettere e spazi e non più di 40 caratteri",
                        },
                        maxLength: {
                          value: 40,
                          message: "Inserire solo lettere e spazi e non più di 40 caratteri",
                        },
                      }}
                      placeholder="Cognome"
                      movingLabel={false}
                      iconName="user"
                      error={errors.surname}
                      touched={touchedFields.surname}
                      check={true}
                      checkError={true}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <Input
                      name="tel"
                      register={register}
                      validation={{
                        validate: (value) => validatePhoneIT(value, 9, 20, true),
                      }}
                      placeholder="Inserisci il tuo numero di telefono"
                      movingLabel={false}
                      iconName="phone"
                      error={errors.tel}
                      touched={touchedFields.tel}
                      check={true}
                      checkError={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Input
                      type="checkbox"
                      name="personalData"
                      register={register}
                      validation={{
                        required: {
                          value: true,
                          message: "Per favore leggi e acconsenti alle norme sulla privacy.",
                        },
                      }}
                      label={
                        typeForm === "PROSP" ? (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: `Dichiaro di aver preso visione dell'<a target="_blank" href="https://www.engie.it/documents/5879978/6032030/Informativa+ricontatto_web_B2C_20180824.pdf/525c437f-64c8-2395-89c0-6f167d586ea5?t=1607592369538" title="Informativa privacy">informativa</a> sul trattamento dei dati personali e autorizzo ENGIE a contattarmi con informazioni su offerte e disponibilità del servizi`,
                            }}
                          ></p>
                        ) : (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: `I tuoi dati personali saranno trattati esclusivamente per gestire la tua richiesta di assistenza sul tuo contratto con ENGIE. Per maggiori informazioni consulta l'<a target="_blank" href="https://www.engie.it/documents/5879978/6032030/Informativa+clienti+persone+fisiche+dei+servizi+ENGIE.pdf/64bf24cb-8872-d9ab-fbc9-c32af99c8ed3?t=1618823282993" title="Informativa privacy">informativa privacy</a>`,
                            }}
                          ></p>
                        )
                      }
                      error={errors.personalData}
                    />
                  </div>
                </div>
                <div className="row mt-auto">
                  <div className="col-12 text-center">
                    <button type="submit" className="widget-chat__form-submit">
                      Richiamatemi
                    </button>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 text-center">
                    <button className="widget-chat__restart" onClick={() => setOpenForm(false)}>
                      Ricomincia da capo
                    </button>
                  </div>
                </div>
              </form>
            )}
            {response && (
              <div className="row h-100">
                <div className="col-12 text-center">
                  <span ref={responseRef} className="scroll-anchor"></span>
                  {response === "ok" ? (
                    <div className="widget-chat__response">
                      <Icon name="circle-check" cls="widget-chat__check" />
                      <p>
                        Grazie per averci contattato,
                        <br />
                        ti richiameremo al più presto!
                      </p>
                      <button
                        className="widget-chat__restart"
                        onClick={() => {
                          setOpenForm(false);
                          setResponse();
                        }}
                      >
                        Ricomincia da capo
                      </button>
                    </div>
                  ) : (
                    <div className="widget-chat__response">
                      <Icon name="circle-xmark" cls="widget-chat__check" />
                      <p>
                        Servizio non disponibile,
                        <br />
                        riprova più tardi
                      </p>
                      <button
                        className="widget-chat__restart"
                        onClick={() => {
                          setOpenForm(false);
                          setResponse();
                        }}
                      >
                        Ricomincia da capo
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </InView>
    </>
  );
};

export default FormChat;
